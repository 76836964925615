<template>
    <div class="content_wpr">
        <div class="section_content w-100">
            <div class="acton_header">
                <h2>Subscriptions</h2>
                <div class="client_info">
                    <div>
                        <h5>{{ selectedClient.full_name }}</h5>
                        <h6>{{ selectedClient.email }}</h6>
                    </div>
                    <img :src="selectedClient.profile_pic ? selectedClient.profile_pic : require('@/assets/images/male-female.png')" alt="">
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions mt-4">
                    <ul>
                        <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search..." @input="isTyping = true" :disabled="clientOrderSubscriptionsLoader" v-model.trim="params.search"/>
                            <button class="search_btn" @click="searchField = !searchField;">
                                <i class="fas fa-search"></i>
                            </button>
                        </li>
                        <li class="break_line"></li>
                    </ul>
                </div>
                <div class="contact_loader" v-if="clientOrderSubscriptionsLoader">
                    <quote-loader />
                </div>
                <table class="show_header" v-show="!clientOrderSubscriptionsLoader">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Interval</th>
                            <th>End</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="clientOrderSubscriptions.total">
                        <tr v-for="(order, o) in clientOrderSubscriptions.data" :key="o">
                            <td><a @click="handleOrderDetails(order);">{{ order.order_id }}</a></td>
                            <td>{{ moment(order.created_at).format('ll | LT') }}</td>
                            <td>
                                <div class="user_wpr">
                                    <h4>{{ selectedClient.full_name }}</h4>
                                </div>
                            </td>
                            <td>{{ order.product ? order.product.name : '-' }}</td>
                            <td v-if="order.product && order.product.pricing_type == 2">{{ companyCurrencySymbol }}{{ parseFloat(order.price).toFixed(2) }}</td>
                            <td v-else-if="order.product && order.product.pricing_type == 3">{{ companyCurrencySymbol }}{{ parseFloat(order.price).toFixed(2) }}</td>
                            <td v-else>-</td>
                            <td>{{ order.product && order.product.billing_frequency ? Helper.capitalizeFirst(order.product.billing_frequency) : '-' }}</td>
                            <td>{{ order.subscriptin_end_date ? moment(order.subscriptin_end_date).format('ll | LT') : '-' }}</td>
                            <td><label>{{  helper.capitalizeFirst(order.status) }}</label></td>
                            <td>
                                <ul class="action_list">
                                    <li v-if="companyUserCan('create', 'checkouts')" title="Refund" @click="handleRefund(order)"><i class="fas fa-reply"></i></li>
                                    <li title="Send Invoice" @click="handleSendInvoice(order)"><i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != (order.status != 'trialing' ? order.id : order.order_id), 'fa fa-spin fa-spinner': sendInvoiceLoader == (order.status != 'trialing' ? order.id : order.order_id) }"></i></li>
                                    <li v-if="companyUserCan('update', 'checkouts')" title="Edit" @click="handleEditSubscription(order)"><i class="fas fa-pencil-alt"></i></li>
                                    <li v-if="companyUserCan('delete', 'checkouts')" title="Cancel" @click="handleCancelSubscription(order)"><i class="fas fa-ban danger"></i></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_footer">
                <ul>
                    <li>{{ clientOrderSubscriptions.from ? clientOrderSubscriptions.from : 0 }} - {{ clientOrderSubscriptions.to ? clientOrderSubscriptions.to : 0 }} of {{ clientOrderSubscriptions.total }}</li>
                </ul>
            </div>
            <div class="pagination" v-show="clientOrderSubscriptions.total">
                <pagination v-model="params.page" :pages="clientOrderSubscriptions.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
    </div>
    <subscription-details v-model="editSubscription" :selected-client="selectedClient" :selected-order="selectedOrder" :refresh-subscriptions="refreshClientOrderSubscriptions" />
    <order-details v-model="orderDetails" :selected-client="selectedClient" :selected-order="selectedOrder" />
    <refund-component v-model="refundPayment" :refund-amount="refundAmount" :order-id="refundOrderId" :subscription-id="subscriptionId" :is-subscription="true" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    const SubscriptionDetails = defineAsyncComponent(() => import('@/pages/checkout/components/SubscriptionDetails'))
    const OrderDetails = defineAsyncComponent(() => import('@/pages/checkout/components/client/OrderDetails'))
    const RefundComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Refund'))

    export default {
        name: 'Client Subscription Tab',

        data () {
            return {
                refundPayment: false,
                orderDetails: false,
                editSubscription: false,
                params: {
                    client_id: '',
                    page: 1,
                    per_page: 10,
                    search: '',
                },
                isTyping: false,
                moment,
                selectedOrder: {},
                refundAmount: 0,
                refundOrderId: 0,
                subscriptionId: 0,
                helper: Helper,
                searchField: false,
                Helper,
            }
        },

        props: {
            selectedClient: {
                type: Object,
                emits: ['update:modelValue'],
                default: () => {},
            },
        },

        watch: {
            'params.search' (val) {
                const vm = this;
                vm.params.search = val;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getClientOrderSubscriptions(vm.params);
                        }
                        if (vm.params.search.length == 0) {
                            vm.params.page = 1;
                            vm.getClientOrderSubscriptions(vm.params);
                        }
                    }
                }
            },
        },

        components:{
            RefundComponent,
            SubscriptionDetails,
            OrderDetails,
        },

        computed: {
            ...mapState({
                clientOrderSubscriptionsLoader: state => state.checkoutModule.clientOrderSubscriptionsLoader,
                clientOrderSubscriptions: state => state.checkoutModule.clientOrderSubscriptions,
                companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
                sendInvoiceLoader: state => state.checkoutModule.sendInvoiceLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },
        mounted () {
            const vm     = this;

            vm.resetForm(vm.params);
        },

        methods:{
            ...mapActions({
                getClientOrderSubscriptions: 'checkoutModule/getClientOrderSubscriptions',
                cancelSubscription: 'checkoutModule/cancelSubscription',
                sendSubscriptionInvoice: 'checkoutModule/sendSubscriptionInvoice',
                sendOrderInvoice: 'checkoutModule/sendOrderInvoice',
            }),

            resetForm () {
                const vm = this;

                vm.params.client_id = vm.selectedClient.id;
                vm.getClientOrderSubscriptions(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getClientOrderSubscriptions(vm.params);
            },

            handleCancelSubscription (order) {
                const vm = this;

                if (order.status == 'canceled') {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already canceled, you can not cancel it again.`, 'OK'));
                } else {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to Cancel this Subscription?`, 'Yes');

                    options.preConfirm = function () {
                                            return vm.cancelSubscription(order.id).then((result) => {
                                                if (result) {
                                                    setTimeout(() => {
                                                        vm.getClientOrderSubscriptions(vm.params);
                                                    }, 2000);
                                                }
                                            });
                                        };

                    Swal.fire(options);
                }
            },

            handleOrderDetails (order) {
                const vm = this;

                vm.selectedOrder  = order;
                vm.orderDetails   = true;
            },

            handleEditSubscription (order) {
                const vm = this;

                vm.selectedOrder    = order;
                vm.editSubscription = true;
            },

            refreshClientOrderSubscriptions () {
                const vm = this;

                vm.getClientOrderSubscriptions(vm.params).then((subscriptions) => {
                    if (subscriptions) {
                        vm.selectedOrder = subscriptions.data.filter(subscription => subscription.id == vm.selectedOrder.id)[0];
                    }
                });
            },

            handleRefund (subscription) {
                const vm = this;


                const lastSubscriptionLog = subscription.subscription_logs && subscription.subscription_logs.length ? subscription.subscription_logs[0] : null;

                if ((subscription.status == 'trialing' && subscription.is_trial_refund) || (lastSubscriptionLog && lastSubscriptionLog.is_refunded)) {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already been refunded, you can not refund it again.`, 'OK'));
                } else {
                    vm.refundAmount    = parseFloat(subscription.price).toFixed(2);
                    vm.refundOrderId   = subscription.order_id;
                    vm.subscriptionId  = lastSubscriptionLog ? lastSubscriptionLog.id : 0;
                    vm.refundPayment   = true;
                }
            },

            handleSendInvoice (subscription) {
                const vm = this;

                if (subscription.status != 'trialing') {
                    vm.sendSubscriptionInvoice(subscription.id);
                } else {
                    vm.sendOrderInvoice(subscription.order_id);
                }
            }
        }
    }
</script>

<style scoped>
    .form_grp h4 {
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
        display: flex;
    }
    .form_grp h4 .btn {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        background: #2f7eed;
        color: #fff;
        cursor: pointer;
        margin-left: 10px;
    }

    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
        text-align: left;
    }

    .result_wpr table td a{
        color: #2f7eed;
        font-weight: 500;
        cursor: pointer;
    }

    .result_wpr table td .action_list{
        display: flex;
        gap: 10px;
    }

    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
    }

    .result_wpr table td label {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        border: 1px solid #9FB1CF;
        border-radius: 11px;
        padding: 3px 10px;
    }

    .form_grp input.no_spin::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }

    .form_grp input[type=number].no_spin {
        -moz-appearance: textfield;
    }
</style>
